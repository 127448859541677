import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

LogoLoading.propTypes = {
  className: PropTypes.string
};

function LogoLoading({ className, ...other }) {
  return (
    <Box
      component="img"
      alt="logo"
      src="/static/brand/logo_full.png"
      height={40}
      className={className}
      {...other}
    />
  );
}

export default LogoLoading;
