import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { varFadeInUp } from 'src/components/Animate';
import { motion, AnimatePresence } from 'framer-motion';
import makeStyles from '@mui/styles/makeStyles';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Cancel from '@mui/icons-material/Cancel';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    borderRadius: theme.shape.borderRadiusMd,
    backgroundColor: theme.palette.background.paper
  }
}));

// ----------------------------------------------------------------------

DialogAnimate.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

function DialogAnimate({
  open = false,
  maxWidth = 'md',
  onClose,
  onReset,
  onConfirm,
  confirmMessage = 'Continuer',
  closeButtonLabel = 'Annuler',
  loading = false,
  disableOnClose,
  children,
  title = 'Animated Dialog',
  className,
  ...other
}) {
  const classes = useStyles();

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          fullWidth
          maxWidth={maxWidth}
          open={open}
          onClose={() => {}}
          PaperComponent={motion.div}
          PaperProps={{ ...varFadeInUp }}
          classes={{ paper: classes.paper }}
          className={clsx(classes.root, className)}
          {...other}
        >
          <DialogTitle>
            <Stack
              direction={'row'}
              sx={{ my: 1 }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">{title}</Typography>
              {onReset && (
                <IconButton onClick={onReset}>
                  <Cancel />
                </IconButton>
              )}
            </Stack>
          </DialogTitle>

          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <Button disabled={disableOnClose} onClick={onClose} color="inherit">
              {closeButtonLabel}
            </Button>
            {onConfirm && (
              <LoadingButton
                loading={loading}
                onClick={onConfirm}
                variant="contained"
              >
                {confirmMessage}
              </LoadingButton>
            )}
          </DialogActions>
        </Dialog>
      )}
    </AnimatePresence>
  );
}

export default DialogAnimate;
