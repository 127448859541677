import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { LocalStorage } from 'src/utils/localStorage';
import { capitalize } from 'lodash';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: {}
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

const setSession = (accessToken, userData) => {
  const localStorage = new LocalStorage();
  if (accessToken) {
    localStorage.setUserJwt(accessToken);
    userData && localStorage.setUserData(JSON.stringify(userData));
  } else {
    localStorage.rmUserJwt();
    localStorage.rmUserData();
  }
};

// ----------------------------------------------------------------------

export function login({ email, password }) {
  const _LOGIN_PATH = 'user/authenticate';

  return async (dispatch) => {
    const response = await axios.post(_LOGIN_PATH, {
      user_email: email,
      password
    });
    console.log('Api Event: user login operation result', response);
    const { data } = response;
    if (data) {
      console.log('Api Event: access token', data.jwt);
      let user = jwtDecode(data.jwt);
      user = {
        displayName: capitalize(user.user_fname).concat(
          ' ' + capitalize(user.user_lname)
        ),
        role: user.user_fname,
        email: user.user_email,
        ...user
      };
      console.log('Api Event: decoded user', JSON.stringify(user));
      setSession(data.jwt, user);
      dispatch(slice.actions.loginSuccess({ user: user }));
    }
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const localStorage = new LocalStorage();
    try {
      const accessToken = localStorage.getUserJwt();
      const userData = localStorage.getUserData();

      console.log('get user data ', userData);
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: JSON.parse(userData)
          })
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}
