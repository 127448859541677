// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  root: '/',
  dashboard: '/dashboard',
  auth: '/auth',
  transactions: '/transactions',
  beneficiaries: '/beneficiaries',
  campaigns: '/campaigns',
  users: '/users',
  recalls: '/recalls',
  devices: '/devices',
  locations: '/locations',
  payments: '/payments'
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
    register: path(ROOTS.auth, '/register'),
    registerUnprotected: path(ROOTS.auth, '/register-unprotected'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    verify: path(ROOTS.auth, '/verify')
  }
};

const TRANSACTION_PATH = {
  root: ROOTS.transactions,
  transaction: path(ROOTS.transactions, '/:transactionId'),
  addTransaction: path(ROOTS.transactions, '/new')
};

const RECALL_PATH = {
  root: ROOTS.recalls,
  addRecall: path(ROOTS.recalls, '/new')
};
const BENEFICIARY_PATH = {
  root: ROOTS.beneficiaries,
  beneficiary: path(ROOTS.beneficiaries, '/:beneficiaryId'),
  addBeneficiary: path(ROOTS.beneficiaries, '/new')
};

const USER_PATH = {
  root: ROOTS.users,
  donators: path(ROOTS.users, '/donators'),
  donator: path(path(ROOTS.users, '/donators'), '/:donatorId'),
  addDonator: path(path(ROOTS.users, '/donators'), '/new'),
  agents: path(ROOTS.users, '/agents'),
  agent: path(path(ROOTS.users, '/agents'), '/:agentId'),
  addAgent: path(path(ROOTS.users, '/agents'), '/new'),
  donatorUsers: path(ROOTS.users, '/donator-users'),
  donatorUser: path(path(ROOTS.users, '/donator-users'), '/:donatorId'),
  addDonatorUser: path(path(ROOTS.users, '/donator-users'), '/new')
};

const PAYMENT_PATH = {
  root: ROOTS.payments,
  transactions: path(ROOTS.payments, '/:payment')
};
const CAMPAIGN_PATH = {
  root: ROOTS.campaigns,
  campaign: path(ROOTS.campaigns, '/:campaignId'),
  addCampaign: path(ROOTS.campaigns, '/new'),
  recalls: path(path(ROOTS.campaigns, '/recalls'), '/:campaignId')
};

const DEVICE_PATH = {
  root: ROOTS.devices,
  addDevice: path(ROOTS.devices, '/new'),
  device: path(ROOTS.devices, '/:deviceId')
};
const LOCATION_PATH = {
  root: ROOTS.locations,
  addLocation: path(ROOTS.locations, '/new'),
  location: path(ROOTS.locations, '/:locationId')
};

export const PATH_APP = {
  dashboard: ROOTS.dashboard,
  root: ROOTS.root,
  transactions: TRANSACTION_PATH,
  beneficiaries: BENEFICIARY_PATH,
  campaigns: CAMPAIGN_PATH,
  recalls: RECALL_PATH,
  devices: DEVICE_PATH,
  locations: LOCATION_PATH,
  payments: PAYMENT_PATH,
  users: USER_PATH
};
