import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardLayout from 'src/layouts/DashboardLayout';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.root,
      component: lazy(() => import('src/views/general/DashboardAppView'))
    },
    {
      exact: true,
      path: PATH_APP.dashboard,
      component: lazy(() => import('src/views/general/DashboardAppView'))
    },

    // TRANSACTION
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.transactions.root,
      component: lazy(() =>
        import('src/views/transaction/TransactionCardsView')
      )
    },
    {
      exact: true,
      path: PATH_APP.transactions.addTransaction,
      component: lazy(() => import('src/views/transaction/AddTransaction'))
    },
    {
      exact: true,
      path: PATH_APP.transactions.transaction,
      component: lazy(() =>
        import('src/views/transaction/TransactionDetailsView')
      )
    },

    // BENEFICIARY
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.beneficiaries.root,
      component: lazy(() =>
        import('src/views/beneficiary/BeneficiaryCardsView')
      )
    },
    {
      exact: true,
      path: PATH_APP.beneficiaries.addBeneficiary,
      component: lazy(() => import('src/views/beneficiary/AddBeneficiary'))
    },
    {
      exact: true,
      path: PATH_APP.beneficiaries.beneficiary,
      component: lazy(() =>
        import('src/views/beneficiary/BeneficiaryDetailsView')
      )
    },

    // RECALL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.recalls.root,
      component: lazy(() => import('src/views/recall/RecallCardsView'))
    },
    {
      exact: true,
      path: PATH_APP.recalls.addRecall,
      component: lazy(() => import('src/views/recall/AddRecall'))
    },
    // USER
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.users.donators,
      component: lazy(() => import('src/views/user/donator/DonatorListView'))
    },
    {
      exact: true,
      path: PATH_APP.users.addDonator,
      component: lazy(() => import('src/views/user/donator/AddDonator'))
    },
    {
      exact: true,
      path: PATH_APP.users.donator,
      component: lazy(() => import('src/views/user/donator/DonatorDetailsView'))
    },

    {
      exact: true,
      path: PATH_APP.users.donatorUsers,
      component: lazy(() =>
        import('src/views/user/donatorUser/DonatorUserListView')
      )
    },
    {
      exact: true,
      path: PATH_APP.users.addDonatorUser,
      component: lazy(() => import('src/views/user/donatorUser/AddDonatorUser'))
    },
    {
      exact: true,
      path: PATH_APP.users.donatorUser,
      component: lazy(() =>
        import('src/views/user/donatorUser/DonatorUserDetailsView')
      )
    },
    {
      exact: true,
      path: PATH_APP.users.agents,
      component: lazy(() => import('src/views/user/agent/AgentListView'))
    },
    {
      exact: true,
      path: PATH_APP.users.addAgent,
      component: lazy(() => import('src/views/user/agent/AddAgent'))
    },
    {
      exact: true,
      path: PATH_APP.users.agent,
      component: lazy(() => import('src/views/user/agent/AgentDetailsView'))
    },

    // CAMPAIGN
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.campaigns.root,
      component: lazy(() => import('src/views/campaign/CampaignListView'))
    },
    {
      exact: true,
      path: PATH_APP.campaigns.recalls,
      component: lazy(() => import('src/views/campaign/CampaignRecallView'))
    },
    {
      exact: true,
      path: PATH_APP.campaigns.addCampaign,
      component: lazy(() => import('src/views/campaign/AddCampaign'))
    },
    {
      exact: true,
      path: PATH_APP.campaigns.campaign,
      component: lazy(() => import('src/views/campaign/CampaignDetailsView'))
    },

    // DEVICE
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.devices.root,
      component: lazy(() => import('src/views/device/DeviceListView'))
    },
    {
      exact: true,
      path: PATH_APP.devices.addDevice,
      component: lazy(() => import('src/views/device/AddDevice'))
    },
    {
      exact: true,
      path: PATH_APP.devices.device,
      component: lazy(() => import('src/views/device/DeviceDetailsView'))
    },

    // LOCATION
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.locations.root,
      component: lazy(() => import('src/views/location/LocationListView'))
    },
    {
      exact: true,
      path: PATH_APP.locations.addLocation,
      component: lazy(() => import('src/views/location/AddLocation'))
    },
    {
      exact: true,
      path: PATH_APP.locations.location,
      component: lazy(() => import('src/views/location/LocationDetailsView'))
    },

    // PAYMENT
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.payments.root,
      component: lazy(() => import('src/views/payment/PaymentCardsView/index'))
    },
    {
      exact: true,
      path: PATH_APP.payments.transactions,
      component: lazy(() => import('src/views/payment/TransactionListView'))
    },

    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;
