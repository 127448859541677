import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { Redirect, useLocation } from 'react-router-dom';
import { PATH_PAGE } from 'src/routes/paths';
import LoadingScreen from 'src/components/LoadingScreen';

// ----------------------------------------------------------------------

AuthProtect.propTypes = {
  children: PropTypes.node
};

function AuthProtect({ children }) {
  const { isLoading, isAuthenticated } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return (
      <Redirect
        to={PATH_PAGE.auth.login.concat(
          `?next=${location.pathname}${location.search}`
        )}
      />
    );
  }

  return <>{children}</>;
}

export default AuthProtect;
