import { useDispatch, useSelector } from 'react-redux';
import { login, logout } from 'src/redux/slices/authJwt';

// ----------------------------------------------------------------------

export default function useAuth() {
  // JWT Auth
  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated } = useSelector(
    (state) => state.authJwt
  );

  // JWT Auth

  return {
    user: user,
    isLoading: isLoading,
    isAuthenticated: isAuthenticated,

    login: ({ email, password }) =>
      dispatch(
        login({
          email: email,
          password: password
        })
      ),

    logout: () => dispatch(logout()),

    resetPassword: () => {}
  };
}
