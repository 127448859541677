import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  driver: null
};

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setDriver(state, action) {
      state.driver = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setDriver } = slice.actions;

// ----------------------------------------------------------------------
