import React from 'react';
import { useSnackbar } from 'notistack';
import { MIconButton } from 'src/theme';
import CloseIcon from '@mui/icons-material/Close';
import { helperError } from 'src/utils/helpError';

export default function useNotify() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showError = (error) => {
    enqueueSnackbar(helperError(error), {
      variant: 'error',
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <CloseIcon />
        </MIconButton>
      )
    });
  };

  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: 'success',
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <CloseIcon />
        </MIconButton>
      )
    });
  };

  return { showSuccess, showError };
}
