import React from 'react';
import { MIcon } from 'src/theme';
import { PATH_APP } from 'src/routes/paths';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import Paid from '@mui/icons-material/Paid';
import Campaign from '@mui/icons-material/Campaign';
import Device from '@mui/icons-material/AdUnits';
import Location from '@mui/icons-material/LocationOn';
import Recall from '@mui/icons-material/CallMissed';
import Payment from '@mui/icons-material/Payment';
import DonatorUser from '@mui/icons-material/PersonPin';
import Agent from '@mui/icons-material/SupervisedUserCircle';

// ----------------------------------------------------------------------

export const USER_ROLES = {
  USER_ROLE_ADMIN: 1,
  USER_ROLE_SUPERAGENT: 2,
  USER_ROLE_AGENT: 3,
  USER_ROLE_DONATOR_USER: 4
};

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  authenticator: <MIcon src={path('ic_authenticator')} />,
  donator: <VolunteerActivismIcon />,
  paid: <Paid />,
  campaign: <Campaign />,
  device: <Device />,
  location: <Location />,
  recall: <Recall />,
  payment: <Payment />,
  agent: <Agent />,
  donatorUser: <DonatorUser />,
  user: <MIcon src={path('ic_user')} />,
  dashboard: <MIcon src={path('ic_dashboard')} />
};

const getMenuConfig = (user) => [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: 'table de board',
        href: PATH_APP.dashboard,
        icon: ICONS.dashboard
      }
    ]
  },
  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'Management',
    items: [
      {
        title: 'bénéficiaires',
        href: PATH_APP.beneficiaries.root,
        icon: ICONS.user,
        hide: user.user_role !== USER_ROLES.USER_ROLE_ADMIN
      },
      {
        title: 'transactions',
        href: PATH_APP.transactions.root,
        icon: ICONS.paid,
        hide: user.user_role !== USER_ROLES.USER_ROLE_ADMIN
      },

      {
        title: 'rappels',
        href: PATH_APP.recalls.root,
        icon: ICONS.recall,
        hide: user.user_role !== USER_ROLES.USER_ROLE_ADMIN
      },

      {
        title: 'paiements',
        href: PATH_APP.payments.root,
        icon: ICONS.payment
      }
    ]
  },

  {
    subheader: 'profils',
    hide: user.user_role !== USER_ROLES.USER_ROLE_ADMIN,
    items: [
      {
        title: 'donateur',
        href: PATH_APP.users.donators,
        icon: ICONS.donator
      },
      {
        title: 'utilisateur-donateur',
        href: PATH_APP.users.donatorUsers,
        icon: ICONS.donatorUser
      },
      {
        title: 'agent',
        href: PATH_APP.users.agents,
        icon: ICONS.agent
      }
    ]
  },
  {
    subheader: 'autre',
    hide: user.user_role !== USER_ROLES.USER_ROLE_ADMIN,

    items: [
      {
        title: 'distributions',
        href: PATH_APP.campaigns.root,
        icon: ICONS.campaign
      },
      {
        title: 'appareils',
        href: PATH_APP.devices.root,
        icon: ICONS.device
      },
      {
        title: 'Localisations',
        href: PATH_APP.locations.root,
        icon: ICONS.location
      }
    ]
  }
];

export default getMenuConfig;
