import 'src/utils/i18n';
import 'src/utils/highlight';
import 'simplebar/src/simplebar.css';
import App from './App';
import 'i18next';
import React from 'react';
import ReactDOM from 'react-dom';

// ----------------------------------------------------------------------

ReactDOM.render(<App />, document.getElementById('root'));
