// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
export const errorcodes = {
  AuthorizationError: 'AuthorizationError'
};

export const errorMessages = {
  'Network Error': {
    code: 'NetworkError',
    message: 'Erreur de réseau, vérifiez votre connexion'
  },
  'No campaigns found': {
    code: 'NotFound',
    message: 'Aucune distribution trouvée pour les filtres sélectionnés'
  },
  'aucune campagne trouvée pour les filtres sélectionnés': {
    code: 'NoCampaignFound',
    message: 'No campaign were found'
  },
  'Invalid username or password': {
    code: 'InvalidUser',
    message: "Nom d'utilisateur ou mot de passe invalide"
  },
  'Invalid user': {
    code: 'InvalidUser',
    message: 'Utilisateur invalide'
  },
  "Jeton d'autorisation fourni non valide": {
    code: 'AuthorizationError',
    message: 'Votre session est terminée, essayez de vous reconnecter'
  },
  'Transaction not found': {
    code: 'NotFound',
    message: 'Aucune transaction trouvée avec cet identifiant'
  },
  'Beneficiary not found': {
    code: 'NotFound',
    message: 'Aucun bénéficiaire trouvé avec cet identifiant'
  },
  'Unknown Error': {
    code: 'UnknownError',
    message: 'Erreur Inconnue, Veuillez contacter le support'
  }
};

const codeErrors = {
  MappingRequiredAttributeMissing: {
    code: 'MappingRequiredAttributeMissing',
    message: ({ index, attribute }) =>
      `element [${index}]: l'attribut requis ${attribute} ne peut pas être trouvé dans les données`
  },
  RejectionExist: {
    code: 'RejectionExist',
    message: () =>
      'Certains éléments ont été rejectés, veuillez les télécharger'
  }
};

export function helperError(error) {
  if (error.code)
    return codeErrors[error.code]
      ? codeErrors[error.code].message(error.metadata)
      : errorMessages['Unknown Error'].message;
  if (error.message)
    return errorMessages[error.message]
      ? errorMessages[error.message].message
      : errorMessages['Unknown Error'].message;
  return errorMessages['Unknown Error'].message;
}

export const codes = {
  // Email
  emailAlreadyinUse: {
    code: 'auth/email-already-in-use',
    text: 'There already exists an account with the given email address.'
  },
  invalidEmail: {
    code: 'auth/invalid-email',
    text: 'The email address is not valid.'
  },
  userDisabled: {
    code: 'auth/user-disabled',
    text: 'The user corresponding to the given credential has been disabled.'
  },
  userNotFound: {
    code: 'auth/user-not-found',
    text: 'There is no user corresponding to the email address.'
  },

  // Password
  wrongPassword: {
    code: 'auth/wrong-password',
    text: 'Wrong password'
  },
  weakPassword: {
    code: 'auth/weak-password',
    text: 'Password should be at least 6 characters'
  }
};

const {
  emailAlreadyinUse,
  invalidEmail,
  userDisabled,
  userNotFound,
  wrongPassword,
  weakPassword
} = codes;

export function emailError(errors) {
  return {
    error:
      errors === emailAlreadyinUse.code ||
      errors === invalidEmail.code ||
      errors === userDisabled.code ||
      errors === userNotFound.code,
    helperText:
      (errors === emailAlreadyinUse.code && emailAlreadyinUse.text) ||
      (errors === invalidEmail.code && invalidEmail.text) ||
      (errors === userDisabled.code && userDisabled.text) ||
      (errors === userNotFound.code && userNotFound.text)
  };
}

export function passwordError(errors) {
  return {
    error: errors === wrongPassword.code || errors === weakPassword.code,
    helperText:
      (errors === wrongPassword.code && wrongPassword.text) ||
      (errors === weakPassword.code && weakPassword.text)
  };
}

export const NO_CAMPAIGN_WERE_FOUND = 'No campaign were found';
