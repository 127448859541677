import axios from 'axios';
import { LocalStorage } from './localStorage';

// ----------------------------------------------------------------------

// for development environment create a .env.development.local file in the root directory
// for production environment create .env.production.local file in the root directory
// place the REACT_APP_BASE_URL env variable in one of the files depend on the environment
// this is a good practice and should be followed.
const _BASE_URL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: _BASE_URL,
  timeout: 50000
});

axiosInstance.interceptors.request.use(
  (req) => {
    req.headers = {
      ...req.headers,
      Authorization: new LocalStorage().getUserJwt()
    };
    return req;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (res) => {
    console.debug('Api Event: resolving result ....', res);
    const { data } = res;
    if (!data) return res;

    const { error, rejected_items, errorType, errorMessage } = data;
    if (error) {
      const cloned =
        typeof error === 'string' ? { message: error } : { ...error };
      console.log('Api Event: Error resolving operation', error);
      if (rejected_items) cloned.rejected_items = rejected_items;

      return Promise.reject(cloned);
    } else if (errorType || errorMessage) {
      const customError = { message: errorMessage, code: errorType };
      return Promise.reject(customError);
    }
    return data;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
